import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 1080.000000" >
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)" >

<path d="M3360 6876 c-5 -13 -103 -276 -219 -583 -115 -307 -223 -594 -240
-638 -17 -44 -31 -83 -31 -88 0 -4 73 -6 161 -5 l161 3 40 113 40 112 275 -2
276 -3 40 -112 40 -113 164 0 c153 0 165 1 160 18 -3 13 -301 805 -472 1257
l-23 60 -181 3 -181 2 -10 -24z m223 -406 c14 -41 50 -147 81 -234 31 -88 54
-161 52 -163 -2 -2 -80 -2 -173 -1 l-171 3 86 243 c47 133 88 239 92 235 4 -4
19 -42 33 -83z"/>
<path d="M4502 6475 l-293 -424 26 -93 c14 -51 31 -108 36 -125 l11 -33 274 0
274 0 0 -120 0 -120 150 0 150 0 0 120 0 120 70 0 71 0 -3 143 -3 142 -67 3
-68 3 0 404 0 405 -167 0 -168 -1 -293 -424z m325 -382 c-3 -2 -68 -2 -146 -1
l-142 3 72 105 c39 58 104 152 143 210 l71 105 3 -209 c1 -115 1 -211 -1 -213z"/>
<path d="M5360 6230 l0 -670 395 0 c376 0 397 1 451 21 178 67 271 255 219
445 -24 89 -83 177 -143 215 l-48 30 41 43 c66 68 89 126 93 229 3 66 0 99
-15 139 -24 68 -96 146 -170 183 l-58 30 -382 3 -383 3 0 -671z m625 390 c50
-19 75 -58 75 -116 0 -46 -4 -57 -34 -88 l-33 -36 -169 0 -169 0 3 125 3 125
149 0 c82 0 160 -4 175 -10z m64 -518 c100 -50 106 -186 12 -249 -32 -22 -43
-23 -217 -23 l-184 0 0 145 0 145 178 0 c149 0 182 -3 211 -18z"/>
<path d="M3010 4689 l0 -670 333 3 c330 4 332 4 413 31 265 89 437 319 451
603 7 128 -13 227 -69 344 -53 110 -166 227 -275 283 -134 70 -184 77 -540 77
l-313 0 0 -671z m678 482 c199 -69 318 -226 337 -441 18 -207 -91 -403 -275
-493 -93 -46 -165 -56 -392 -57 l-168 0 0 511 0 511 218 -4 c191 -4 224 -7
280 -27z"/>
<path d="M4772 5288 c-17 -40 -55 -134 -85 -208 -30 -74 -139 -341 -241 -592
-102 -252 -186 -460 -186 -463 0 -3 42 -5 94 -5 l94 0 64 160 63 160 332 0
331 0 64 -160 63 -160 98 0 c53 0 97 2 97 5 0 3 -38 99 -84 213 -47 114 -168
412 -270 662 l-185 455 -109 3 -110 3 -30 -73z m272 -458 l126 -315 -257 -3
c-141 -1 -258 0 -261 2 -2 3 53 149 123 326 70 177 131 318 136 313 4 -4 64
-150 133 -323z"/>
<path d="M5580 5280 l0 -80 215 0 215 0 0 -590 0 -590 90 0 90 0 0 590 0 590
215 0 215 0 0 80 0 80 -520 0 -520 0 0 -80z"/>
<path d="M7187 5353 c-5 -8 -14 -30 -292 -713 -95 -234 -191 -469 -213 -522
l-41 -98 97 0 97 0 64 160 64 160 331 0 332 0 63 -160 64 -160 98 0 99 0 -19
48 c-23 57 -257 632 -353 867 -38 94 -92 227 -120 298 l-51 127 -108 0 c-59 0
-110 -3 -112 -7z m243 -522 c66 -166 120 -306 120 -311 0 -6 -97 -10 -260 -10
l-260 0 19 48 c11 26 65 162 120 302 132 333 121 308 131 289 5 -9 63 -152
130 -318z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
